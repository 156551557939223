import React, { useState, useEffect, useRef, Suspense } from "react";
import "../util/reset.css";
import classNames from "classnames/bind";
import styles from "./BrandList.module.scss";
import List from "../atoms/List";
import { motion, useWillChange, AnimatePresence } from "framer-motion";
import Chip from "../atoms/Chip";
import Icon from "../atoms/Icon";
import { ReactComponent as IconRight } from "../svgs/IconRight.svg";
import { ReactComponent as IconMore } from "../svgs/IconMore.svg";

import { ReactComponent as IconShrink } from "../svgs/IconShrink.svg";
import LoadedImage from "../atoms/LoadedImage";
import { API_URI, S3_URI } from "../shortcut";
import { ReactComponent as IconExternalLink } from "../svgs/IconExternalLink.svg";

const cx = classNames.bind(styles);

const sample_data = [
  {
    _id: { $oid: "67d56ec3f7c5f2e6e029187e" },
    id: "10000-10000",
    name: "이건",
    material_type: ["창문", "문", "마루"],
    is_distributor: 0,
    expose: 1,
    image: "data/brand/image/10000-10000",
    contact: {
      url: "http://www.eagonstore.com/front/main.do",
      phone: "1522-1271",
      email: "sinida@eagon.com",
    },
    name_alt: ["이건창호", "이건마루"],
    accessibility: {
      website: 1,
      web_data: 1,
      offline: 0,
      agency: 0,
      catalog: 1,
    },
  },
  {
    _id: { $oid: "67d56ec3f7c5f2e6e0291880" },
    id: "12000-10002",
    name: "금진목재",
    material_type: ["목구조"],
    contact: {
      url: "http://www.kumjin.co.kr/",
      phone: "032-584-8851,2",
      email: "kumjin8851@hanmail.net",
    },
    accessibility: {
      website: 1,
      web_data: 1,
      offline: 0,
      agency: 0,
      catalog: 1,
    },
  },
  {
    _id: { $oid: "67d56ec3f7c5f2e6e0291880" },
    id: "12000-10003",
    name: "금진목재",
    material_type: ["목구조"],
    contact: {
      url: "http://www.kumjin.co.kr/",
      phone: "032-584-8851,2",
      email: "kumjin8851@hanmail.net",
    },
    accessibility: {
      website: 1,
      web_data: 1,
      offline: 0,
      agency: 0,
      catalog: 1,
    },
  },
  {
    _id: { $oid: "67d56ec3f7c5f2e6e0291880" },
    id: "12000-10004",
    name: "금진목재",
    material_type: ["목구조"],
    contact: {
      url: "http://www.kumjin.co.kr/",
      phone: "032-584-8851,2",
      email: "kumjin8851@hanmail.net",
    },
    accessibility: {
      website: 1,
      web_data: 1,
      offline: 0,
      agency: 0,
      catalog: 1,
    },
  },
  {
    _id: { $oid: "67d56ec3f7c5f2e6e0291880" },
    id: "12000-10005",
    name: "금진목재",
    material_type: ["목구조"],
    contact: {
      url: "http://www.kumjin.co.kr/",
      phone: "032-584-8851,2",
      email: "kumjin8851@hanmail.net",
    },
    accessibility: {
      website: 1,
      web_data: 1,
      offline: 0,
      agency: 0,
      catalog: 1,
    },
  },
  {
    _id: { $oid: "67d56ec3f7c5f2e6e029187e" },
    id: "10000-10020",
    name: "이건",
    material_type: ["창문", "문", "마루"],
    is_distributor: 0,
    expose: 1,
    image: "data/brand/image/10000-10000",
    contact: {
      url: "http://www.eagonstore.com/front/main.do",
      phone: "1522-1271",
      email: "sinida@eagon.com",
    },
    name_alt: ["이건창호", "이건마루"],
    accessibility: {
      website: 1,
      web_data: 1,
      offline: 0,
      agency: 0,
      catalog: 1,
    },
  },
  {
    _id: { $oid: "67d56ec3f7c5f2e6e029187e" },
    id: "10000-10030",
    name: "이건",
    material_type: ["창문", "문", "마루"],
    is_distributor: 0,
    expose: 1,
    image: "data/brand/image/10000-10000",
    contact: {
      url: "http://www.eagonstore.com/front/main.do",
      phone: "1522-1271",
      email: "sinida@eagon.com",
    },
    name_alt: ["이건창호", "이건마루"],
    accessibility: {
      website: 1,
      web_data: 1,
      offline: 0,
      agency: 0,
      catalog: 1,
    },
  },
  {
    _id: { $oid: "67d56ec3f7c5f2e6e029187e" },
    id: "10000-10100",
    name: "이건",
    material_type: ["창문", "문", "마루"],
    is_distributor: 0,
    expose: 1,
    image: "data/brand/image/10000-10000",
    contact: {
      url: "http://www.eagonstore.com/front/main.do",
      phone: "1522-1271",
      email: "sinida@eagon.com",
    },
    name_alt: ["이건창호", "이건마루"],
    accessibility: {
      website: 1,
      web_data: 1,
      offline: 0,
      agency: 0,
      catalog: 1,
    },
  },
  {
    _id: { $oid: "67d56ec3f7c5f2e6e029187e" },
    id: "10000-10200",
    name: "이건",
    material_type: ["창문", "문", "마루"],
    is_distributor: 0,
    expose: 1,
    image: "data/brand/image/10000-10000",
    contact: {
      url: "http://www.eagonstore.com/front/main.do",
      phone: "1522-1271",
      email: "sinida@eagon.com",
    },
    name_alt: ["이건창호", "이건마루"],
    accessibility: {
      website: 1,
      web_data: 1,
      offline: 0,
      agency: 0,
      catalog: 1,
    },
  },
  {
    _id: { $oid: "67d56ec3f7c5f2e6e029187e" },
    id: "10000-10300",
    name: "이건",
    material_type: ["창문", "문", "마루"],
    is_distributor: 0,
    expose: 1,
    image: "data/brand/image/10000-10000",
    contact: {
      url: "http://www.eagonstore.com/front/main.do",
      phone: "1522-1271",
      email: "sinida@eagon.com",
    },
    name_alt: ["이건창호", "이건마루"],
    accessibility: {
      website: 1,
      web_data: 1,
      offline: 0,
      agency: 0,
      catalog: 1,
    },
  },
];

const BrandList = ({
  clicked_type,
  setClickedType,
  sticky_top,
  onClick = () => {},
}) => {
  const [data, setData] = useState({ hovered: null, clicked: null });
  return (
    <List
      type="column"
      align="left"
      gap={0.5}
      style={{
        maxHeight: `calc(100vh - ${sticky_top + 48 + 32}px)`,
        overflowY: "auto",
        paddingBottom: "32px",
      }}
      className={cx("wrapper")}
    >
      <List
        type="column"
        align="left"
        gap={0.75}
        style={{
          position: "sticky",
          top: 0,
          backgroundColor: "#ffffff",
          padding: "12px 0",
          width: "100%",
          zIndex: 2,
        }}
      >
        <List
          type="column"
          align="left"
          gap={0.75}
          style={{ padding: "4px 16px" }}
        >
          <List type="row" gap={0}>
            <div className={cx("text-section")}>{clicked_type.rank0}</div>
            <IconRight className={cx("icon-section")} />
            <div className={cx("text-section")}>{clicked_type.rank1}</div>
            <IconRight className={cx("icon-section")} />
            <div className={cx("text-section", "emph")}>
              {clicked_type.rank2}
            </div>
          </List>
        </List>
        {clicked_type?.rank3_list && (
          <>
            <List
              type="row"
              gap={0.375}
              multiple_line
              style={{ padding: "0 18px" }}
            >
              <Chip
                clickable
                type="grey"
                clicked={clicked_type.rank3_clicked == null}
                onClick={() =>
                  setClickedType((data) => ({
                    ...data,
                    rank3_clicked: null,
                  }))
                }
              >
                모든 세부유형
              </Chip>
              {clicked_type.rank3_list.map((rank3) => (
                <Chip
                  clickable
                  clicked={clicked_type.rank3_clicked == rank3.name}
                  type="grey"
                  onClick={() =>
                    setClickedType((data) => ({
                      ...data,
                      rank3_clicked: rank3.name,
                    }))
                  }
                >
                  {rank3.name}
                </Chip>
              ))}
            </List>
            <div className={cx("horz-seperator")} />
          </>
        )}
        <List
          type="row"
          gap={0.375}
          multiple_line
          style={{ padding: "0 18px" }}
        >
          <Chip
            clickable
            type="grey"
            clicked={clicked_type.filter == null}
            onClick={() =>
              setClickedType((data) => ({
                ...data,
                filter: null,
              }))
            }
          >
            모든 업체
          </Chip>
          {["대리점보유", "오프라인샵보유", "제조사"].map((filter) => (
            <Chip
              clickable
              type="grey"
              clicked={clicked_type.filter === filter}
              onClick={() =>
                setClickedType((data) => ({
                  ...data,
                  filter: filter,
                }))
              }
            >
              {filter}
            </Chip>
          ))}
        </List>
      </List>
      <List tight={false}>
        {sample_data.map((brand) => (
          <List tight={false}>
            <motion.div
              layout="position"
              transition={{ duration: 0.3, type: "easeIn" }}
              onMouseEnter={() => {
                setData((data) => ({ ...data, hovered: brand.id }));
              }}
              onMouseLeave={() => {
                setData((data) => ({ ...data, hovered: null }));
              }}
              key={brand.id}
              className={cx(
                "frame-brand",
                data.clicked === brand.id
                  ? "clicked"
                  : data.hovered === brand.id && "hover"
              )}
              onClick={() => {
                window.open(brand.contact.url, brand.id);
              }}
            >
              <List
                type="row"
                align="center"
                attach="space"
                gap={0.5}
                tight={false}
                style={{ height: "100%" }}
              >
                <List type="row" gap={0.5} align="center" tight={false}>
                  <Suspense
                    fallback={
                      <div
                        style={{
                          width: "36px",
                          minWidth: "36px",
                          height: "36px",
                          backgroundColor: "#f0f0f0",
                        }}
                      />
                    }
                  >
                    <LoadedImage
                      src={brand.image && S3_URI + brand.image}
                      width={36}
                      height={36}
                      style={{ minWidth: "36px" }}
                    />
                  </Suspense>
                  <motion.div
                    layout="position"
                    transition={{ duration: 0.3, type: "easeIn" }}
                  >
                    <List gap={0} align="left" tight={false}>
                      <List type="row" gap={0.25} align="center" tight={false}>
                        <div
                          className={cx(
                            "title",
                            data.hovered === brand.id && "hover"
                          )}
                        >
                          {brand.name}
                        </div>

                        {data.hovered === brand.id &&
                          data.clicked !== brand.id && (
                            <>
                              {brand.is_distributor === 1 && (
                                <Chip type="grey">유통사</Chip>
                              )}
                              {brand.accessibility?.offline === 1 && (
                                <Chip type="grey">오프라인샵</Chip>
                              )}
                              {brand.accessibility?.agency === 1 && (
                                <Chip type="grey">대리점보유</Chip>
                              )}
                              {brand.accessibility?.catalog === 1 && (
                                <Chip type="grey">카탈로그보유</Chip>
                              )}
                            </>
                          )}
                      </List>
                      {data.hovered === brand.id &&
                        data.clicked !== brand.id && (
                          <List
                            type="row"
                            gap={0.125}
                            align="center"
                            tight={false}
                          >
                            <div className={cx("illust")}>
                              {brand.contact?.phone}
                            </div>
                            <div className={cx("illust")}>·</div>
                            <div className={cx("illust")}>
                              {brand.contact?.email}
                            </div>
                          </List>
                        )}
                    </List>
                  </motion.div>
                </List>
                {data.clicked === brand.id ? (
                  <IconShrink
                    onClick={(e) => {
                      setData((data) => ({ ...data, clicked: null }));
                      e.stopPropagation();
                    }}
                    className={cx("icon-btn")}
                  />
                ) : (
                  data.hovered === brand.id && (
                    <IconMore
                      onClick={(e) => {
                        setData((data) => ({ ...data, clicked: brand.id }));
                        e.stopPropagation();
                      }}
                      className={cx("icon-btn")}
                    />
                  )
                )}
              </List>
            </motion.div>
            <AnimatePresence>
              {data.clicked === brand.id && (
                <motion.div
                  className={cx("frame-brand-dropdown")}
                  initial={{
                    opacity: 0,
                    height: 0,
                    marginBottom: 0,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  animate={{
                    opacity: 1,
                    height: "auto",
                    marginBottom: 8,
                    paddingTop: 12,
                    paddingBottom: 12,
                  }}
                  exit={{
                    opacity: 0,
                    height: 0,
                    marginBottom: 0,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  transition={{ duration: 0.3 }}
                >
                  <List gap={1.125} align="left" attach="space" tight={false}>
                    <List gap={0.5} tight={false} align="left">
                      <div className={cx("section")}>다루는 자재 유형</div>
                      <List type="row" gap={0.25} multiple_line tight={false}>
                        {brand.material_type.map((type) => (
                          <Chip type="grey" clickable>
                            {type}
                          </Chip>
                        ))}
                      </List>
                    </List>
                    <List gap={0.5} tight={false} align="left">
                      <div className={cx("section")}>연락처</div>
                      <List gap={0.25} align="left" tight={false}>
                        <div className={cx("illust")}>
                          {brand.contact?.phone}
                        </div>
                        <div className={cx("illust")}>
                          {brand.contact?.email}
                        </div>
                      </List>
                    </List>
                    <List gap={0.5} tight={false} align="left">
                      <div className={cx("section")}>업체정보</div>
                      <List type="row" gap={0.25} multiple_line tight={false}>
                        {brand.material_type.map((type) => (
                          <>
                            {brand.is_distributor === 1 && (
                              <Chip type="grey">유통사</Chip>
                            )}
                            {brand.accessibility?.offline === 1 && (
                              <Chip type="grey">오프라인샵</Chip>
                            )}
                            {brand.accessibility?.agency === 1 && (
                              <Chip type="grey">대리점보유</Chip>
                            )}
                            {brand.accessibility?.catalog === 1 && (
                              <Chip type="grey">카탈로그보유</Chip>
                            )}
                          </>
                        ))}
                      </List>
                    </List>
                    <List type="row" gap={0} tight={false} align="left">
                      <div
                        className={cx("section", "clickable")}
                        onClick={() => {
                          window.open(brand.contact.url, brand.id);
                        }}
                      >
                        홈페이지 방문하기
                      </div>
                      <List type="row" style={{ height: "19px" }}>
                        <IconExternalLink className={cx("icon-light")} />
                      </List>
                    </List>
                  </List>
                </motion.div>
              )}
            </AnimatePresence>
          </List>
        ))}
      </List>
    </List>
  );
};

export default BrandList;
