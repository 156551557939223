import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { _formatUrl } from "../util/alias";

const LoadedImage = ({
  className,
  src = "https://ezdxf.readthedocs.io/en/stable/_images/hatch_pattern_4.png",
  width,
  height,
  onError = console.log,
  duration = 0,
  delay = 0,
  onClick = () => {},
  style = {},
}) => {
  // fetch로 이미지를 blob으로 받아와 object URL을 생성한 뒤, 이미지가 로드될 때까지 대기
  const loadImage = async (src) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(src);
        if (!response.ok) {
          return reject(new Error(`이미지 로드 실패: ${response.statusText}`));
        }
        const blob = await response.blob();
        const objectURL = URL.createObjectURL(blob);
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = objectURL;
        img.onload = () => {
          resolve(objectURL);
        };
        img.onerror = (error) => {
          console.error("이미지 로드 오류:", error);
          // onError 콜백으로 에러를 처리한 후 fallback URL로 resolve합니다.
          onError(new Error("이미지를 로드하는 중 에러가 발생했습니다."));
        };
      } catch (error) {
        reject(error);
      }
    });
  };
  const { data: imageUrl } = useQuery({
    queryKey: [_formatUrl(src)],
    queryFn: () => loadImage(_formatUrl(src)),
    suspense: true,
    onError: (error) => {
      console.error("이미지 로드 중 에러 발생", error);
    },
  });

  useEffect(() => {
    // 컴포넌트 언마운트 시나 src가 변경될 때 object URL을 정리하여 메모리 누수를 방지
    return () => {
      if (imageUrl) {
        try {
          URL.revokeObjectURL(imageUrl);
        } catch (e) {
          onError(e);
        }
      }
    };
  }, [imageUrl]);

  return (
    <motion.img
      className={className}
      src={imageUrl}
      width={width}
      height={height}
      onError={onError}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: duration,
        delay: delay,
      }}
      onClick={onClick}
      style={{ pointerEvents: "none", ...style }}
    />
  );
};

export default LoadedImage;
