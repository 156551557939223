import React, {
  useEffect,
  useReducer,
  useState,
  Suspense,
  useLayoutEffect,
} from "react";
import "../util/reset.css";
import classNames from "classnames/bind";
import styles from "./MaterialBrandPage.module.scss";
import useGlobalVar, { getCookie } from "../hooks/useGlobalVar";
import List from "../atoms/List";

import Header from "./Header";

import useGlobalData from "../hooks/useGlobalData";
import { _setFiniteInterval, _transformScroll } from "../util/alias";

import ItemList from "../component/ItemList";
import Filter from "../component/Filter";

import TableCard from "../component/TableCard";
import Button from "../atoms/Button";

import ScrollContainer from "react-indiana-drag-scroll";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import {
  convertBrandRecommendDataForBrandPage,
  getBrandPageQueryOfBrandRecommendData,
} from "../functions/handleBrandRecommendData";
import { API_URI } from "../shortcut";
import {
  convertBrandDataForBrandPage,
  getBrandPageQueryOfBrandData,
} from "../functions/handleBrandData";
import Popup from "../component/Popup";
import { MATERIAL_TYPE } from "../functions/types";
import CustomLink from "../component/CustomLink";
import useQueryParam from "../hooks/useQueryParam";
import MetaData from "./MetaData";
import BrandCard from "../component/BrandCard";
import Chip from "../atoms/Chip";
import { getQueryOfMaterialBrandData } from "../functions/handleMaterialBrandData";
import BrandTypeList from "../component/BrandTypeList";
import BrandList from "../component/BrandList";
import LoadedImage from "../atoms/LoadedImage";
import { ReactComponent as IconBackground } from "../svgs/IconMaterialBrandPageShortcutBackground.svg";
import { ReactComponent as IconStructure } from "../svgs/IconMaterialBrandPageShortcutStructure.svg";
import { ReactComponent as IconSearch } from "../svgs/IconSearch.svg";
import { ReactComponent as IconRoof } from "../svgs/IconMaterialBrandPageShortcutRoof.svg";
import { ReactComponent as IconCeiling } from "../svgs/IconMaterialBrandPageShortcutCeiling.svg";
import { ReactComponent as IconWall } from "../svgs/IconMaterialBrandPageShortcutWall.svg";
import { ReactComponent as IconSlab } from "../svgs/IconMaterialBrandPageShortcutSlab.svg";
import { ReactComponent as IconSkin } from "../svgs/IconMaterialBrandPageShortcutSkin.svg";
import { ReactComponent as IconWindow } from "../svgs/IconMaterialBrandPageShortcutWindow.svg";
import { ReactComponent as IconDoor } from "../svgs/IconMaterialBrandPageShortcutDoor.svg";
import { ReactComponent as IconVertical } from "../svgs/IconMaterialBrandPageShortcutVertical.svg";
import { ReactComponent as IconKitchen } from "../svgs/IconMaterialBrandPageShortcutKitchen.svg";
import { ReactComponent as IconBathroom } from "../svgs/IconMaterialBrandPageShortcutBathroom.svg";
import { ReactComponent as IconOutdoor } from "../svgs/IconMaterialBrandPageShortcutOutdoor.svg";
import { ReactComponent as IconEnergy } from "../svgs/IconMaterialBrandPageShortcutEnergy.svg";
import { ReactComponent as IconSystem } from "../svgs/IconMaterialBrandPageShortcutSystem.svg";
import { ReactComponent as IconGoto } from "../svgs/IconGoto.svg";
import { ReactComponent as IconHistory } from "../svgs/IconHistory.svg";

const cx = classNames.bind(styles);

const sample_like_data = ["바스데이", "대림바스"];

const MaterialBrandPage = ({ match }) => {
  const [global_var, setGlobalVar] = useGlobalVar();
  const [global_data, setGlobalData] = useGlobalData();
  const [brand_recommend_data, setBrandRecommendData] = useState([]);
  const [brand_data, setBrandData] = useState([]);
  const [material_type, setMaterialType] = useState([]);
  const [clicked_type, setClickedType] = useState({});
  const [emph_brand, setEmphBrand] = useState(null);
  const [parallax, setParallax] = useState([0, 0]);
  const [hovered_type, setHoveredType] = useState([]);
  const [shortcut_state, setShortcutState] = useState({
    hover: null,
    clicked: null,
  });
  const [shortcut_components, setShortcutComponents] = useState([[]]);
  const [search_state, setSearchState] = useState({
    hover: false,
    clicked: false,
    text: null,
  });
  const [turning_point, setTurningPoint] = useState({
    shortcut_split_num: 1,
    shortcut_top: 0,
    brand_type_row_num: 3,
    show_shortcut: true,
    fold_types: false,
  });

  useLayoutEffect(() => {
    const row_num =
      global_var.window_width > 1050
        ? 3
        : global_var.window_width > 900
        ? 2
        : 1;

    const split_num = global_var.window_width > 950 ? 1 : 2;

    const shortcut_top =
      global_var.window_width > 950
        ? 0
        : global_var.window_width > 760
        ? 95
        : -95 - 16;
    const show_shortcut = global_var.window_width > 760;
    const fold_types = global_var.window_width <= 760;
    setTurningPoint({
      shortcut_top,
      shortcut_split_num: split_num,
      brand_type_row_num: row_num,
      show_shortcut,
      fold_types,
    });
  }, [global_var.window_width]);

  useEffect(() => {
    const initial_comonents = [
      [
        {
          text: "구조",
          comp: <IconStructure />,
          link_path: ["구조"],
        },
        {},
        {
          text: "지붕",
          comp: <IconRoof />,
          link_path: ["마감", "지붕"],
        },
        {
          text: "천장",
          comp: <IconCeiling />,
          link_path: ["마감", "천장"],
        },
        {
          text: "벽체",
          comp: <IconWall />,
          link_path: ["마감", "벽체"],
        },
        {
          text: "바닥",
          comp: <IconSlab />,
          link_path: ["마감", "바닥"],
        },
        {
          text: "스킨·실외",
          comp: <IconSkin />,
          link_path: ["마감", "스킨·실외"],
        },
        {},
        {
          text: "창",
          comp: <IconWindow />,
          link_path: ["오프닝", "창"],
        },
        {
          text: "문",
          comp: <IconDoor />,
          link_path: ["오프닝", "문"],
        },
        {
          text: "수직동선",
          comp: <IconVertical />,
          link_path: ["오프닝", "수직동선"],
        },
        {},
        {
          text: "주방",
          comp: <IconKitchen />,
          link_path: ["공간", "주방"],
        },
        {
          text: "화장실",
          comp: <IconBathroom />,
          link_path: ["공간", "화장실"],
        },
        {
          text: "공용·실외",
          comp: <IconOutdoor />,
          link_path: ["공간", "공용부·실외"],
        },
        {},
        {
          text: "에너지",
          comp: <IconEnergy />,
          link_path: ["에너지"],
        },
        {},
        {
          text: "시스템",
          comp: <IconSystem />,
          link_path: ["시스템"],
        },
      ],
    ];
    const new_components = [];
    const chunk_size = Math.ceil(
      initial_comonents[0].length / turning_point.shortcut_split_num
    );
    for (let i = 0; i < initial_comonents[0].length; i += chunk_size) {
      new_components.push(initial_comonents[0].slice(i, i + chunk_size));
    }
    setShortcutComponents(new_components);
  }, [turning_point.shortcut_split_num]);

  // useEffect(() => {
  // const handleMouseMove = (e) => {
  // const { innerWidth, innerHeight } = window;
  // 마우스의 X 위치를 윈도우 중앙과 비교하여 -1 ~ 1 사이의 값을 구하고, 원하는 오프셋 크기로 조정
  //const offsetX = ((e.clientX - innerWidth / 2) / innerWidth) * 50; // 50px 정도의 최대 오프셋 (원하는 값으로 조정)
  //const offsetY = ((e.clientY - innerHeight / 2) / innerHeight) * 50; // 50px 정도의 최대 오프셋 (원하는 값으로 조정)

  // 반대 방향으로 오프셋 적용
  //setParallax([-offsetX, -offsetY]);
  //};

  //window.addEventListener("mousemove", handleMouseMove);
  //return () => window.removeEventListener("mousemove", handleMouseMove);
  //}, []);

  const query = useQueryParam();

  useLayoutEffect(() => {
    axios
      .put(
        API_URI + "data",
        getBrandPageQueryOfBrandRecommendData(
          [],
          global_var.data_sort,
          global_data.search_data?.filter?.current ||
            (query.get("search") ?? "")
        )
      )
      .then((res) =>
        setBrandRecommendData(convertBrandRecommendDataForBrandPage(res.data))
      )
      .catch(console.log);
  }, [global_var.data_sort, global_data.search_data]);

  useLayoutEffect(() => {
    axios
      .put(API_URI + "data", getQueryOfMaterialBrandData())
      .then((result) => result.data[0] && setMaterialType(result.data[0].data))
      .catch(console.log);
  }, [brand_recommend_data]);

  return (
    <>
      <MetaData path="/materialbrand" />
      <Header emph="materialbrand" />
      <div className={cx("wrapper")}>
        <List gap={0} style={{ width: "100%", height: "%" }}>
          <div className={cx("body")}>
            <List gap={0} tight={false}>
              <List gap={0} tight={false}>
                <div className={cx("frame-top")} />
                <div className={cx("frame-title")}>
                  <List gap={3} align="center" attach="space" tight={false}>
                    {/* <motion.img
                      src="/img/MaterialBrandPage/sampleMaterials.png"
                      className={cx("img-title")}
                    /> */}
                    <Suspense
                      fallback={<div style={{ position: "absolute" }}></div>}
                    >
                      {/* 애니메이션 컨테이너: 두 개의 이미지를 가로로 배치 */}
                      <motion.div
                        style={{
                          display: "flex",
                          position: "absolute",
                          top: "120px",
                        }}
                        initial={{ x: "25%" }}
                        // 컨테이너 전체 너비의 50%만큼 왼쪽으로 이동하면, 두 번째 이미지가 첫 번째 이미지 자리를 대체함
                        animate={{ x: "-25%" }}
                        transition={{
                          duration: 40,
                          ease: "linear",
                          repeat: Infinity,
                        }}
                      >
                        <LoadedImage
                          src="/img/MaterialBrandPage/sampleMaterials.png"
                          className={cx("img-title")}
                          duration={0.5}
                          delay={0.6}
                        />
                        <LoadedImage
                          src="/img/MaterialBrandPage/sampleMaterials.png"
                          className={cx("img-title")}
                          duration={0.5}
                          delay={0.6}
                        />
                      </motion.div>
                    </Suspense>

                    <List gap={0.5} align="center">
                      <motion.div
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{
                          duration: 0.5,
                        }}
                        className={cx("text-title")}
                      >
                        건축 자재업체 한눈에 보기
                      </motion.div>
                      <motion.div
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{
                          delay: 0.3,
                          duration: 0.5,
                        }}
                        className={cx("text-illust")}
                      >
                        200개의 유형으로 분류된 1,000여 개의 국내 건축
                        자재업체를 빠르게 찾아보세요.
                      </motion.div>
                    </List>
                    <List type="row" gap={1} style={{ zIndex: 2 }}>
                      <div className={cx("btn-title")}>살펴보기</div>
                      <div className={cx("btn-title")}>
                        새로운 업체 등록 요청하기 +
                      </div>
                    </List>
                  </List>
                </div>
                <div
                  style={{
                    position: "sticky",
                    top: 48,
                    zIndex: 2,
                    width: "100%",
                    backgroundColor: "white",
                  }}
                >
                  <div style={{ width: "100%", height: "3rem" }}></div>
                  <List gap={1.5} tight={false}>
                    <div
                      className={cx(
                        "frame-search",
                        search_state.hover && "hover",
                        search_state.clicked && "clicked"
                      )}
                      onMouseEnter={() =>
                        setSearchState((prev) => ({ ...prev, hover: true }))
                      }
                      onMouseLeave={() =>
                        setSearchState((prev) => ({ ...prev, hover: false }))
                      }
                      onClick={() =>
                        setSearchState((prev) => ({ ...prev, clicked: true }))
                      }
                      onBlur={() =>
                        setSearchState((prev) => ({ ...prev, clicked: false }))
                      }
                    >
                      <List
                        type="row"
                        gap={0.5}
                        tight={false}
                        align="center"
                        style={{ height: "3rem" }}
                      >
                        <IconSearch className={cx("icon-search")} />
                        <input
                          className={cx("input")}
                          placeholder="자재유형, 업체 등 자유롭게 검색하세요."
                        ></input>
                      </List>
                      {search_state.clicked && (
                        <motion.div className={cx("dropdown")}>
                          <List>
                            <div className={cx("dropdown-component")}>
                              <List type="row" gap={0.5}>
                                <IconGoto className={cx("icon-search")} />
                                {/* <div className={cx("text-small")}>자재유형</div> */}
                                <div className={cx("text-normal")}>
                                  {"구조 > 벽"}
                                  <b className={cx("text-emph")}>돌</b>
                                  {", 블럭 > ALC"}
                                </div>
                              </List>
                            </div>
                            <div className={cx("dropdown-component")}>
                              <List type="row" gap={0.5}>
                                <IconGoto className={cx("icon-search")} />
                                <div className={cx("text-normal")}>
                                  {"공간 > 주방 > "}
                                  <b className={cx("text-emph")}>주방빌트인</b>
                                </div>
                              </List>
                            </div>
                            <div className={cx("dropdown-component")}>
                              <List type="row" gap={0.5}>
                                {" "}
                                <IconGoto className={cx("icon-search")} />
                                <div className={cx("text-normal")}>
                                  에<b className={cx("text-emph")}>너지</b>
                                  {" > 단열"}
                                </div>
                              </List>
                            </div>
                            <div className={cx("dropdown-component")}>
                              <List type="row" gap={0.5}>
                                {" "}
                                <IconHistory className={cx("icon-search")} />
                                <div className={cx("text-normal")}>
                                  <b className={cx("text-emph")}>{"한샘"}</b>
                                </div>
                              </List>
                            </div>
                          </List>
                        </motion.div>
                      )}
                    </div>
                    {turning_point.show_shortcut && (
                      <List
                        type="column"
                        align="center"
                        gap={0.5}
                        tight={false}
                      >
                        {shortcut_components.map((group) => (
                          <List
                            type="row"
                            attach={
                              global_var.window_width > 950 ? "space" : "center"
                            }
                            gap={global_var.window_width > 950 ? 0 : 1}
                            align="center"
                            tight={false}
                          >
                            {group.map((shortcut) =>
                              shortcut.text ? (
                                <List
                                  gap={0.25}
                                  className={cx("frame-shortcut")}
                                  onMouseEnter={() =>
                                    setShortcutState((s) => ({
                                      ...s,
                                      hover: shortcut.text,
                                    }))
                                  }
                                  onMouseLeave={() =>
                                    setShortcutState((s) => ({
                                      ...s,
                                      hover: null,
                                    }))
                                  }
                                  onClick={() => {
                                    setShortcutState({
                                      hover: null,
                                      clicked: shortcut.text,
                                    });
                                    const element = document.getElementById(
                                      shortcut.link_path[
                                        shortcut.link_path.length - 1
                                      ]
                                    );
                                    if (element) {
                                      const elementPosition =
                                        element.getBoundingClientRect().top +
                                        window.scrollY;
                                      window.scrollTo({
                                        top:
                                          elementPosition -
                                          (48 +
                                            254 +
                                            turning_point.shortcut_top),
                                        behavior: "smooth",
                                      });
                                    }
                                    _setFiniteInterval(
                                      () =>
                                        setHoveredType({
                                          rank0: shortcut.link_path[0] ?? null,
                                          rank1: shortcut.link_path[1] ?? null,
                                          rank2: shortcut.link_path[2] ?? null,
                                        }),
                                      100,
                                      10
                                    );
                                  }}
                                >
                                  <div
                                    className={cx(
                                      "icon",
                                      shortcut_state.hover === shortcut.text &&
                                        "hover",
                                      shortcut_state.clicked ===
                                        shortcut.text && "clicked"
                                    )}
                                    style={
                                      global_var.window_width > 1200
                                        ? {}
                                        : { transform: "scale(0.85)" }
                                    }
                                  >
                                    <IconBackground
                                      className={cx(
                                        "icon-back",
                                        shortcut_state.hover ===
                                          shortcut.text && "hover",
                                        shortcut_state.clicked ===
                                          shortcut.text && "clicked"
                                      )}
                                    />
                                    {React.cloneElement(shortcut.comp, {
                                      className: cx(
                                        "icon-emph",
                                        shortcut_state.hover ===
                                          shortcut.text && "hover",
                                        shortcut_state.clicked ===
                                          shortcut.text && "clicked"
                                      ),
                                    })}
                                  </div>
                                  <div
                                    className={cx(
                                      "text",
                                      shortcut_state.hover === shortcut.text &&
                                        "hover",
                                      shortcut_state.clicked ===
                                        shortcut.text && "clicked"
                                    )}
                                  >
                                    {shortcut.text}
                                  </div>
                                </List>
                              ) : (
                                <div
                                  className={cx("frame-shortcut-seperator")}
                                ></div>
                              )
                            )}
                          </List>
                        ))}
                      </List>
                    )}
                  </List>
                  <div
                    style={{
                      width: "100%",
                      height: "3rem",
                      backgroundColor: "white",
                    }}
                  ></div>
                </div>
                <List type="row" gap={1} align="left" tight={false}>
                  <BrandTypeList
                    data={material_type}
                    sticky_top={48 + 254 + turning_point.shortcut_top}
                    hovered_state={hovered_type}
                    row_num={turning_point.brand_type_row_num}
                    mobile={turning_point.fold_types}
                    onClick={setClickedType}
                  />
                  <div
                    className={cx("frame-brands")}
                    style={{
                      position: "sticky",
                      top:
                        120 + 122 + 48 + 16 + turning_point.shortcut_top + "px",
                    }}
                  >
                    {clicked_type.rank0 && (
                      <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 1 }}
                        transition={{
                          delay: 0.1,
                          duration: 0.5,
                          type: "spring",
                          stiffness: 100,
                        }}
                      >
                        <BrandList
                          clicked_type={clicked_type}
                          setClickedType={setClickedType}
                          sticky_top={120 + 122 + 48 + 16}
                        />
                      </motion.div>
                    )}
                  </div>
                </List>
                <List
                  type="column"
                  align="left"
                  attach="left"
                  gap={3}
                  tight={false}
                  fillY
                  style={{ alignContent: "flex-start" }}
                >
                  <div className={cx("frame-margin")}></div>
                  {MATERIAL_TYPE.filter(
                    (mat_type) =>
                      brand_recommend_data.filter(
                        (data) =>
                          data.material_type.includes(mat_type) &&
                          brand_data[data.brand_id] != undefined
                      ).length !== 0
                  ).map((mat_type, mat_idx) => (
                    <motion.div
                      key={mat_type + mat_idx + global_data.filter?.emph_now}
                      className={cx("frame-div")}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 1 }}
                      transition={{
                        delay: mat_idx * 0.1,
                        duration: 0.5,
                        type: "spring",
                        stiffness: 100,
                      }}
                    >
                      {
                        <List
                          gap={2}
                          tight={false}
                          align="left"
                          style={{ height: "auto" }}
                        >
                          <List
                            type="row"
                            gap={1.5}
                            // onMouseLeave={() => {
                            //   setEmphBrand(null);
                            // }}
                          >
                            <div className={cx("text-section")}>{mat_type}</div>
                            <List type="row" gap={0.5}>
                              <Chip clickable>전체</Chip>
                              <Chip clickable>#티엠티티</Chip>
                              <Chip clickable>#티엠티티</Chip>
                              <Chip clickable>#티엠티티</Chip>
                            </List>
                          </List>
                          <div className={cx("frame-scroll")}>
                            <List
                              type="row"
                              gap={1}
                              tight={false}
                              multiple_line
                            >
                              <Suspense>
                                {brand_recommend_data
                                  .filter(
                                    (data) =>
                                      data.material_type.includes(mat_type) &&
                                      brand_data[data.brand_id] != undefined
                                  )
                                  .map((data, idx) => (
                                    <BrandCard
                                      key={data.brand_id}
                                      id={data.brand_id}
                                      collection="brand"
                                      title={
                                        data.name +
                                        (data.brand_type == "유통사"
                                          ? "[유통사]"
                                          : "")
                                      }
                                      illust={[
                                        brand_data[data.brand_id]?.site_title,
                                        brand_data[data.brand_id]?.illust,
                                      ].join(" - ")}
                                      illust2={data.specialty}
                                      like={sample_like_data.includes(
                                        data.brand_id
                                      )}
                                      like_num={
                                        brand_data[data.brand_id]?.like_num
                                      }
                                      src={brand_data[data.brand_id]?.src}
                                      onClick={() =>
                                        window.open(
                                          brand_data[data.brand_id]?.url
                                        )
                                      }
                                      onMouseEnter={() => {
                                        setEmphBrand({
                                          mat_type,
                                          data: brand_data[data.brand_id],
                                        });
                                      }}
                                    ></BrandCard>
                                  ))}
                              </Suspense>
                            </List>
                          </div>
                          {/* {
                            <motion.div
                              animate={
                                emph_brand?.mat_type === mat_type
                                  ? { height: 256, opacity: 1 }
                                  : { height: 0, opacity: 0 }
                              }
                              className={cx("frame-illust")}
                            ></motion.div>
                          } */}
                        </List>
                      }
                    </motion.div>
                  ))}
                  <div className={cx("frame-margin")}></div>
                </List>

                {/* </div> */}
              </List>
            </List>
          </div>
        </List>
      </div>
    </>
  );
};

export default MaterialBrandPage;
