import React, { Component, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { GlobalVarProvider } from "./hooks/useGlobalVar";
import { GlobalDataProvider } from "./hooks/useGlobalData";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

import {
  Header,
  HomePage,
  MaterialPage,
  MaterialIllustPage,
  BrandPage,
  BrandIllustPage,
  MapPage,
  ArchitectureIllustPage,
  MaterialBoardPage,
  MaterialBrandPage,
  NotFound,
  RAQPage,
  SurveyPage,
  SpecialPage,
  BrickPage,
} from "./pages";
import { createRoot } from "react-dom/client";

const queryClient = new QueryClient();

const App = ({}) => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/survey") {
      document.body.classList.add("survey");
    } else {
      document.body.classList.remove("about");
    }
  }, [location.pathname]);

  return (
    <GlobalVarProvider>
      <GlobalDataProvider>
        <QueryClientProvider client={queryClient}>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/material" component={MaterialPage} />
            <Route exact path="/special" component={SpecialPage} />
            <Route exact path="/material/:id" component={MaterialIllustPage} />
            <Route exact path="/brand" component={BrandPage} />
            <Route exact path="/materialbrand" component={MaterialBrandPage} />
            <Route exact path="/brick" component={BrickPage} />
            <Route exact path="/brand/:id" component={BrandIllustPage} />
            <Route exact path="/map" component={MapPage} />
            <Route
              exact
              path="/architecture/:id"
              component={ArchitectureIllustPage}
            />
            <Route exact path="/materialboard" component={MaterialBoardPage} />
            <Route
              exact
              path="/materialboard/:type"
              component={MaterialBoardPage}
            />
            <Route exact path="/RAQ" component={RAQPage} />
            {/* <Route exact path="/" component={HomePage} /> */}

            {/* <Route exact path="/material" component={MaterialPage} />
            <Route exact path="/shared/:data" component={HomePage} /> */}
            <Route exact path="/survey" component={SurveyPage} />
            <Route exact path="/notfound" component={NotFound} />
            <Route path="/:id" component={SpecialPage} />
          </Switch>
        </QueryClientProvider>
      </GlobalDataProvider>
    </GlobalVarProvider>
  );
};

export default App;
