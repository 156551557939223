import React, { useState, useEffect, useRef } from "react";
import "../util/reset.css";
import classNames from "classnames/bind";
import styles from "./BrandTypeList.module.scss";
import List from "../atoms/List";
import { motion } from "framer-motion";
import useGlobalVar from "../hooks/useGlobalVar";

const cx = classNames.bind(styles);

const BrandTypeList = ({
  data,
  sticky_top,
  hovered_state,
  row_num = 3,
  mobile = false,
  onClick = () => {},
}) => {
  const [hovered_data, setHoveredData] = useState({
    rank0: null,
    rank1: null,
    rank2: null,
  });
  const [clicked_data, setClickedData] = useState({
    rank0: null,
    rank1: null,
    rank2: null,
  });
  const [circleTop, setCircleTop] = useState(0);
  const [containerTop, setContainerTop] = useState(0);
  const containerRef = useRef(null);
  // 각 대분류 블록의 ref를 저장할 객체
  const blockRefs = useRef({});

  useEffect(() => {
    setHoveredData(hovered_state);
    setContainerTop(containerRef.current.getBoundingClientRect().top);
  }, [hovered_state]);

  useEffect(() => onClick(clicked_data), [clicked_data]);

  // 현재 hover된 대분류 블록의 위치를 업데이트하는 함수
  const updateCirclePosition = () => {
    const { rank0 } = hovered_data;
    if (rank0 && blockRefs.current[rank0] && containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const elementRect = blockRefs.current[rank0].getBoundingClientRect();
      const scrollTop = containerRef.current.scrollTop;
      setCircleTop(elementRect.top - containerRect.top + scrollTop);
    }
  };

  // 대분류 블록에 마우스가 들어왔을 때 실행
  const handleBlockMouseEnter = (rank0Name) => {
    setHoveredData((prev) => ({ ...prev, rank0: rank0Name }));
    // 해당 블록의 위치 계산
    if (blockRefs.current[rank0Name] && containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const elementRect = blockRefs.current[rank0Name].getBoundingClientRect();
      setCircleTop(elementRect.top - containerRect.top);
    }
  };

  // 마우스가 벗어날 때 hover 상태를 초기화
  const handleBlockMouseLeave = () => {
    setHoveredData((prev) => ({ ...prev, rank0: null }));
  };

  // hovered_data.rank0가 변경되거나 스크롤 시 동그라미 위치 업데이트
  useEffect(() => {
    if (!mobile) {
      updateCirclePosition();
      const container = containerRef.current;
      container.addEventListener("scroll", updateCirclePosition);
      window.addEventListener("scroll", updateCirclePosition);

      return () => {
        container.removeEventListener("scroll", updateCirclePosition);
        window.removeEventListener("scroll", updateCirclePosition);
      };
    }
  }, [hovered_data.rank0, hovered_data.rank1, hovered_data.rank2, mobile]);

  useEffect(() => {
    if (!mobile) {
      const setContainerTopValue = () =>
        setContainerTop(containerRef.current.getBoundingClientRect().top);
      setContainerTopValue();
      let scrollTimeout;
      const handleScroll = () => {
        setContainerTopValue();
        if (scrollTimeout) clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
          // 스크롤이 멈춘 후 측정
          setContainerTopValue();
        }, 200); // 200ms 동안 추가 스크롤 이벤트가 없으면 스크롤 종료로 간주
      };
      const container = containerRef.current;
      container.addEventListener("wheel", handleScroll);
      return () => {
        container.removeEventListener("wheel", handleScroll);
        if (scrollTimeout) clearTimeout(scrollTimeout);
      };
    }
  }, [mobile]);

  return !mobile ? (
    <div
      className={cx("brand-list-container")}
      ref={containerRef}
      style={{ position: "relative", width: "100%" }} // 동그라미의 기준이 될 컨테이너
    >
      {/* framer-motion을 활용한 동그라미 요소 */}

      <motion.div
        animate={{
          top:
            circleTop + containerTop - sticky_top + 8 < 0
              ? -containerTop + sticky_top + 16 - 8
              : circleTop + 16,
          scaleX: 1,
          scaleY: [1, 0.8, 1],
        }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
        className={cx("circle")}
        style={{
          position: "absolute",
          left: 8, // 필요에 따라 조정
          width: 20,
          height: 20,
          borderRadius: "50%",
        }}
      />

      <List
        type="column"
        gap={1}
        tight={false}
        style={{ position: "relative" }}
      >
        {data.map((rank0, rank0_idx) => (
          <div
            id={rank0.name}
            key={rank0_idx}
            onMouseEnter={() => handleBlockMouseEnter(rank0.name)}
            onMouseLeave={handleBlockMouseLeave}
            ref={(el) => (blockRefs.current[rank0.name] = el)}
            style={{ width: "100%" }}
          >
            <List
              type="row"
              align="left"
              padding={0.5}
              gap={0.5}
              tight={false}
              onMouseEnter={() =>
                setHoveredData((data) => ({ ...data, rank0: rank0.name }))
              }
              onMouseLeave={() =>
                setHoveredData((data) => ({ ...data, rank0: null }))
              }
            >
              <div
                className={cx(
                  "text-title",
                  "stickyHeader",
                  hovered_data.rank0 === rank0.name && "hover",
                  clicked_data.rank0 === rank0.name && "hover"
                )}
                style={{
                  marginLeft: "24px",
                  width: "150px",
                  top: sticky_top + "px",
                }}
              >
                {rank0.name}
              </div>
              <List type="column" align="left" gap={1} tight={false}>
                {rank0.children
                  .concat(
                    Array.from(
                      {
                        length:
                          (row_num - (rank0.children.length % row_num)) %
                          row_num,
                      },
                      () => ({ name: "", children: [] })
                    )
                  )
                  .reduce(
                    (acc, _, i, arr) =>
                      i % row_num === 0
                        ? acc.concat([arr.slice(i, i + row_num)])
                        : acc,
                    []
                  )
                  .map((rank1_list, rl_idx) => (
                    <List
                      key={rl_idx}
                      type="row"
                      align="left"
                      gap={0.5}
                      tight={false}
                    >
                      {rank1_list.map((rank1, rank1_idx) => (
                        <List
                          id={rank1.name}
                          key={rank1_idx}
                          type="column"
                          align="left"
                          gap={1}
                          tight={false}
                          onMouseEnter={() =>
                            setHoveredData((data) => ({
                              ...data,
                              rank1: rank1.name,
                            }))
                          }
                          onMouseLeave={() =>
                            setHoveredData((data) => ({ ...data, rank1: null }))
                          }
                          style={{ height: "100%" }}
                        >
                          <div
                            className={cx(
                              "text-title",
                              "stickyHeader",
                              "light",
                              hovered_data.rank0 === rank0.name &&
                                hovered_data.rank1 === rank1.name &&
                                "hover",
                              clicked_data.rank0 === rank0.name &&
                                clicked_data.rank1 === rank1.name &&
                                "hover"
                            )}
                            style={{ top: sticky_top + "px" }}
                          >
                            {rank1.name}
                          </div>
                          <List
                            type="column"
                            align="left"
                            gap={0.5}
                            tight={false}
                          >
                            {[
                              rank1.children.filter((e) => e.type === "배경"),
                              rank1.children.filter((e) => e.type === "요소"),
                            ]
                              .filter((e) => e.length > 0)
                              .map((group, g_idx) => (
                                <React.Fragment key={g_idx}>
                                  {g_idx !== 0 && (
                                    <div className={cx("horz-seperator")} />
                                  )}
                                  <List
                                    type="column"
                                    align="left"
                                    gap={0.25}
                                    tight={false}
                                  >
                                    {group.map((rank2, rank2_idx) => (
                                      <div
                                        key={rank2_idx}
                                        className={cx(
                                          "text-normal",
                                          hovered_data.rank0 === rank0.name &&
                                            hovered_data.rank1 === rank1.name &&
                                            "hover-parent",
                                          clicked_data.rank0 === rank0.name &&
                                            clicked_data.rank1 === rank1.name &&
                                            "hover-parent",
                                          hovered_data.rank0 === rank0.name &&
                                            hovered_data.rank1 === rank1.name &&
                                            hovered_data.rank2 === rank2.name &&
                                            "hover",
                                          clicked_data.rank0 === rank0.name &&
                                            clicked_data.rank1 === rank1.name &&
                                            clicked_data.rank2 === rank2.name &&
                                            "clicked"
                                        )}
                                        onMouseEnter={() =>
                                          setHoveredData((data) => ({
                                            ...data,
                                            rank2: rank2.name,
                                          }))
                                        }
                                        onMouseLeave={() =>
                                          setHoveredData((data) => ({
                                            ...data,
                                            rank2: null,
                                          }))
                                        }
                                        onClick={() => {
                                          setClickedData({
                                            rank0: rank0.name,
                                            rank1: rank1.name,
                                            rank2: rank2.name,
                                            rank3_list: rank2.children,
                                          });
                                        }}
                                      >
                                        {rank2.name}
                                      </div>
                                    ))}
                                  </List>
                                </React.Fragment>
                              ))}
                          </List>
                        </List>
                      ))}
                    </List>
                  ))}
              </List>
            </List>
          </div>
        ))}
      </List>
    </div>
  ) : (
    <></>
  );
};

export default BrandTypeList;
