import React, { Children, useState, useEffect } from "react";
// { useEffect }

import styles from "./Like.module.scss";
import classNames from "classnames/bind";

import { ReactComponent as IconLike } from "../svgs/IconLike.svg";
import List from "../atoms/List";

const cx = classNames.bind(styles);

const Like = ({
  like_num = 0,
  like = true,
  onClick = (e, like) => {
    console.log("clicked like component");
    // console.log(e, like);
  },
  show_num = true,
  visible,
}) => {
  // 아래에 개별 아이콘 호출어 추가

  const [mouse_over, setMouseOver] = useState(false);
  const [new_like_num, setNewLikeNum] = useState(like_num);
  const [new_like, setNewLike] = useState(like);

  useEffect(() => {
    setNewLike(like);
  }, [like]);

  useEffect(() => {
    setNewLikeNum(like_num);
  }, [like_num]);

  return (
    <div
      className={cx("wrapper", visible && "visible")}
      onMouseOver={() => {
        setMouseOver(true);
      }}
      onMouseLeave={() => {
        setMouseOver(false);
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e, !new_like);
        setNewLikeNum((n) => (new_like ? n - 1 : n + 1));
        setNewLike((e) => !e);
      }}
    >
      <List align="right">
        <div
          className={cx(
            "frame-icon",
            new_like ? "like" : "",
            mouse_over ? "hover" : ""
          )}
        >
          <IconLike></IconLike>
        </div>
        {show_num && <div className={cx("text-like")}>{new_like_num}</div>}
      </List>
    </div>
  );
};

export default Like;
