import React, { useEffect, useReducer, useState, useRef, useMemo } from "react";
import "../util/reset.css";
import classNames from "classnames/bind";
import styles from "./HomePage.module.scss";
import useGlobalVar, { getCookie } from "../hooks/useGlobalVar";
import List from "../atoms/List";
import CardList from "../component/CardList";
import Header from "./Header";
import {
  default_assumption_data,
  getCardListData,
  getValueData,
} from "../util/deprecated/formatCardListData";
import SnackbarOverlay from "../component/SnackbarOverlay";
import NumberpadOverlay from "../component/NumberpadOverlay";
import { decodeToNumberList, encodeNumberList } from "../util/encodeData";
import { formatData } from "../util/formatData";
import useGlobalData from "../hooks/useGlobalData";
import {
  _replicateAndShuffle,
  _replicateAndShuffleToSize,
  _transformScroll,
} from "../util/alias";
import PopupOverlay from "../component/PopupOverlay";
import DataPage from "../component/DataPage";
import { Route } from "react-router-dom";
import MaterialPage from "./MaterialPage";
import Button from "../atoms/Button";
import NavCard from "../component/NavCard";
import NavCardList from "../component/NavCardList";
import { ReactComponent as IconScrollDown } from "../svgs/IconScrollDown.svg";
import {
  animate,
  motion,
  useMotionValue,
  useTransform,
  useAnimation,
} from "framer-motion";
import Popup from "../component/Popup";
import { SURVEY_PATH } from "../shortcut";
import _ from "lodash";
import { ipad_width } from "../util/style";
import { useInView } from "react-intersection-observer";
import PrivacyPolicy from "./PrivacyPolicy";
import MetaData from "./MetaData";

const cx = classNames.bind(styles);

const ScatterGatherAnimation = ({ urls_with_random_config }) => {
  const controls = useAnimation();
  const [inView, setInView] = React.useState(false);

  const handleInView = () => {
    setInView(true);
  };

  useEffect(() => {
    if (inView) {
      async function sequence() {
        // 흩어지는 애니메이션
        await controls.start((index) => ({
          opacity: 1,
          scale: urls_with_random_config[index].scale,
          x: urls_with_random_config[index].x + "%",
          y: urls_with_random_config[index].y + "%",
          rotate: urls_with_random_config[index].rotate + "deg",
          transition: {
            duration: 0.6,
            type: "spring",
            stiffness: 150,
            delay: urls_with_random_config[index].delay * 0.1,
          },
        }));

        // 대기 시간
        await new Promise((resolve) => setTimeout(resolve, 1200)); // 2초 대기

        // 일렬로 정렬되는 애니메이션
        await controls.start((index) => ({
          x: (index - urls_with_random_config.length / 2) * 120 + "%", // 이미지가 수평으로 일렬로 정렬되도록
          y: -50 + "%", // 줄 바꿈
          rotate: 0,
          scale: 1,
          transition: {
            duration: 1.5,
            ease: [0.16, 1, 0.3, 1.0],
          },
        }));
      }

      sequence();
    }
  }, [controls, inView, urls_with_random_config]);

  return (
    <div className={cx("frame-full-overlay")} style={{ opacity: 0.7 }}>
      {urls_with_random_config.map((data, index) => (
        <motion.img
          key={index}
          src={data.url}
          alt={`scatter-${index}`}
          className={cx("image", "absolute")}
          custom={index}
          initial={{
            opacity: 0,
            scale: data.scale * 0.8,
            x: 0.9 * data.x + "%",
            y: 0.9 * data.y + "%",
          }}
          animate={controls}
          whileInView={handleInView}
          viewport={{ once: true }}
        />
      ))}
    </div>
  );
};

const HomePage = ({ match }) => {
  const [emph, setEmph] = useState("home");
  const page_list = ["건축자재", "브랜드", "지도"];
  const my_page_list = ["머티리얼보드", "견적요청"];
  const [global_var, setGlobalVar] = useGlobalVar();
  const [global_data, setGlobalData] = useGlobalData();

  const wrapper = useRef();
  const [scrollTop, setScrollTop] = useState(20);
  const color = {
    k: "#ffffff",
    g_f: "#969696",
    g: "#EAEAEA",
    p_f: "#BBA58F",
    w: "#FFFFFF",
    p_d: "#523D35",
  };
  useEffect(() => {
    const handleScroll = (e) => {
      setScrollTop(wrapper.current?.scrollTop);
    };
    const current = wrapper.current;
    if (current) {
      current.addEventListener("scroll", handleScroll);
    }
    return () => {
      current?.removeEventListener("scroll", handleScroll);
    };
  }, [wrapper.current]);

  const text_list = ["DIGGING", "MATCHING", "SHOPPING", "SEARCHING"];
  const gap_time = 20;
  const stop_time = 10;
  const anim_time = 10;
  const fps = 10;

  const text_scenario = useMemo(
    () =>
      text_list
        .map((text) => [
          ...text.split("").map((e, idx) => text.substring(0, idx + 1)),
          ...new Array(gap_time).fill(0).map((e) => text),
          ...text
            .split("")
            .map((e, idx) => text.substring(0, text.length - idx)),
          ...new Array(stop_time).fill(0).map((e) => "\u2002"),
        ])
        .reduce((pre, curr) => [...pre, ...curr]),
    []
  );

  const typeTexts = (count, max_count, text_scenario) => {
    return text_scenario[
      Math.ceil(text_scenario.length * (count / max_count)) - 1
    ];
  };

  const count = useMotionValue(0);
  const anim_text = useTransform(count, (latest) =>
    typeTexts(latest, anim_time * fps, text_scenario)
  );

  useEffect(() => {
    const controls = animate(count, anim_time * fps, {
      duration: anim_time,
      repeat: Infinity,
      ease: "linear",
    });

    return controls.stop;
  }, []);

  const imageUrls = [
    "img/materialboard_sample/sample_1_1.jpg",
    "img/materialboard_sample/sample_1_2.jpg",
    "img/materialboard_sample/sample_1_3.webp",
    "img/materialboard_sample/sample_1_4.avif",
    "img/materialboard_sample/sample_2_1.jpg",
    "img/materialboard_sample/sample_2_2.jpg",
    "img/materialboard_sample/sample_2_3.jpg",
    "img/materialboard_sample/sample_3_1.jpg",
    "img/materialboard_sample/sample_3_2.jpg",
    "img/materialboard_sample/sample_3_3.webp",
    "img/materialboard_sample/sample_3_4.jpg",
    "img/materialboard_sample/sample_3_5.jpg",
    "img/materialboard_sample/sample_3_6.png",
    "img/materialboard_sample/sample_1_1.jpg",
  ];

  const abundunt_urls = useMemo(() => {
    return _replicateAndShuffleToSize(imageUrls, 15, 10);
  }, []);

  const urls_with_random_config = useMemo(() => {
    const list = [];
    imageUrls.forEach((e, idx) => {
      const x = 500 * Math.random() - 250 - 50;
      const y = 500 * Math.random() - 250 - 50;
      const scale = Math.random() + 0.5;
      const delay = idx;
      const rotate = 20 * Math.random() - 10;
      list.push({ x, y, scale, delay, rotate, url: e });
    });
    return list;
  }, []);

  const RowAnimation = () => {
    const [ref, inView] = useInView({
      triggerOnce: true, // 뷰포트에 진입할 때 한 번만 트리거
      rootMargin: "-100px", // 뷰포트 밖에서도 트리거되도록 설정
    });
    return (
      <div className={cx("frame-full-overlay")}>
        <List type="row" attach="center" style={{ rotate: "15deg" }}>
          {abundunt_urls.map((line, line_idx) => (
            <motion.div
              key={line_idx} // 추가된 key 속성
              ref={ref}
              initial={{ y: line_idx % 2 === 0 ? "-50%" : "-10%" }}
              animate={{ y: line_idx % 2 === 0 ? "-10%" : "-50%" }}
              transition={{
                repeat: Infinity,
                duration: 25,
                ease: "linear",
                repeatType: "reverse",
              }}
            >
              <List>
                {line.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    alt={`animation-${index}`}
                    className={cx("image")}
                  />
                ))}
              </List>
            </motion.div>
          ))}
        </List>
      </div>
    );
  };

  return (
    <>
      <MetaData path="/" />
      <div className={cx("wrapper")} ref={wrapper}>
        <Header
          emph="home"
          close={
            scrollTop > wrapper.current?.clientHeight - 1 &&
            scrollTop <=
              wrapper.current?.scrollHeight -
                2 * wrapper.current?.clientHeight +
                1
          }
        />
        <div className={cx("frame-first")}>
          <List align="center">
            <div className={cx("frame-top")}>
              <List
                align="center"
                attach="center"
                gap={2}
                tight={false}
                style={{
                  minHeight: "calc(calc(0.5 * var(--new100vh)) - 5rem)",
                }}
              >
                <List align="center" gap={0.5} tight={false}>
                  <List type="row" align="center" gap={0.5}>
                    <div className={cx("text-logo")}>MATERIAL</div>
                    <motion.div className={cx("text-placeholder")}>
                      {anim_text}
                    </motion.div>
                    <div className={cx("text-logo")}>PLATFORM</div>
                  </List>
                  <div className={cx("text-title")}>
                    마감재, 하드웨어 등 원하는 건축자재를 <br></br>
                    쉽고 빠르게 찾아봐요
                  </div>
                  <div className={cx("text-title")}></div>
                </List>
                <List type="row" align="center" gap={1}>
                  <Button
                    color="green-light"
                    onClick={() => {
                      wrapper.current.scrollTo({
                        top: wrapper.current?.clientHeight,
                        behavior: "smooth",
                      });
                    }}
                    GTM={"HomePage_설명보기"}
                  >
                    설명 보기
                  </Button>
                  <Button
                    color="brown-light"
                    onClick={() => window.open(SURVEY_PATH, "설문조사")}
                    GTM={"HomePage_설문조사"}
                  >
                    설문조사하기
                  </Button>
                </List>
              </List>
            </div>
            <div className={cx("frame-bottom")}>
              <NavCardList />
            </div>
          </List>
          <div className={cx("frame-footer")}>
            <List type="row" attach="left" gap={0.5} tight>
              {[
                " 팀 머티리얼플랫폼 ",
                "·",
                "E : team.materialplatform@gmail.com",
              ].map((e) => (
                <div
                  style={{
                    color: color.g_f,
                    fontWeight: 400,
                    whiteSpace: "nowrap",
                  }}
                >
                  {e}
                </div>
              ))}
              <Button
                color="nav"
                onClick={() =>
                  setGlobalData({ popup_data: { id: "privacy_policy" } })
                }
                style={{ overflow: "visible" }}
              >
                <div
                  className={"GTM-HomePage_개인정보처리방침"}
                  style={{ color: color.g_f }}
                >
                  개인정보 처리 방침
                </div>
              </Button>
            </List>
          </div>
        </div>
        <PrivacyPolicy />
      </div>
    </>
  );
};

export default HomePage;
