import _ from "lodash";
import React from "react";

export const _default = (options, default_json) => {
  options = options || {};
  for (let prop in default_json) {
    options[prop] =
      typeof options[prop] !== "undefined" ? options[prop] : default_json[prop];
  }
  return options;
};

export const _getBoundingBox = (x, y) => {
  return {
    left: Math.min(...x),
    right: Math.max(...x),
    top: Math.min(...y),
    bottom: Math.max(...y),
  };
};

export const _center = (x, y) => {
  let bb = _getBoundingBox(x, y);
  return { x: (bb.left + bb.right) / 2, y: (bb.top + bb.bottom) / 2 };
};

export const _centroid = (x, y) => {
  return { x: _avg(x), y: _avg(y) };
};

export const _sum = (list) => list.reduce((a, b) => a + b, 0);
export const _avg = (list) => _sum(list) / list.length || 0;

export const _fillZeros = (data, length) => {
  if (data.length < length) {
    return _fillZeros("0" + data, length);
  }
  return data;
};

export const _stringfy = (data) => {
  if (!data) {
    return "";
  }
  if (typeof data == typeof { abc: "abc" }) {
    return "";
  }
  if (typeof data != typeof "abc") {
    try {
      return data.toString();
    } catch {
      return "";
    }
  }
  return data;
};

export const _transformScroll = (event) => {
  if (!event.deltaY) {
    return;
  }
  event.currentTarget.scrollLeft += 0.5 * event.deltaY + 0.5 * event.deltaX;
  event.preventDefault();
};

export const _addTransformScrollEvent = (id) => {
  let listener = document
    .getElementById(id)
    .addEventListener("wheel", _transformScroll);
  return () => {
    document.getElementById(id).removeEventListener("wheel", listener);
  };
};

export const _ifValidString = (
  data,
  successMapper = () => true,
  err_text = false,
  disallow_zero = false
) => {
  if (typeof data == typeof "11" && data != "") {
    if (disallow_zero && data == "0") {
      return err_text;
    }
    return successMapper(data);
  }
  return err_text;
};

export const _isValidString = (data, err_text, disallow_zero = false) => {
  return _ifValidString(data, (data) => data, err_text, disallow_zero);
};

export const isMobile = () => {
  try {
    document.createEvent("TouchEvent");
    return true;
  } catch (e) {
    return false;
  }
};

export const _isValidEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const _deepcopy = (obj, hash = new WeakMap()) => {
  // 기본형 값이나 null/undefined는 바로 반환
  if (Object(obj) !== obj) return obj;

  // 이미 복사된 객체를 다시 복사하지 않도록 체크
  if (hash.has(obj)) return hash.get(obj);

  let result;
  // 날짜 객체 복사
  if (obj instanceof Date) {
    result = new Date(obj);
  }
  // 정규 표현식 객체 복사
  else if (obj instanceof RegExp) {
    result = new RegExp(obj.source, obj.flags);
  }
  // 배열 복사
  else if (Array.isArray(obj)) {
    result = [];
    hash.set(obj, result);
    obj.forEach((item, index) => {
      result[index] = _deepcopy(item, hash);
    });
  }
  // 일반 객체 복사
  else {
    result = obj.constructor ? new obj.constructor() : Object.create(null);
    hash.set(obj, result);
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        result[key] = _deepcopy(obj[key], hash);
      }
    }
  }

  return result;
};

export const _replicateAndShuffle = (arr, N) => {
  // 복제
  const replicatedArr = _.flatten(Array(N).fill(arr));

  // 전체 섞기
  const shuffledArr = _.shuffle(replicatedArr);

  // N * m 형태의 더블 리스트로 변환
  const chunkedArr = _.chunk(shuffledArr, arr.length);

  return chunkedArr;
};

export const _replicateAndShuffleToSize = (arr, N, M) => {
  const totalElementsNeeded = N * M;

  // Calculate how many times we need to replicate arr to exceed totalElementsNeeded
  const repeatCount = Math.ceil(totalElementsNeeded / arr.length);

  // Replicate the array
  const replicatedArr = _.flatten(Array(repeatCount).fill(arr));

  // Shuffle the replicated array
  const shuffledArr = _.shuffle(replicatedArr);

  // Cut the shuffled array to have exactly N*M elements
  const truncatedArr = shuffledArr.slice(0, totalElementsNeeded);

  // Split the truncated array into N arrays of length M
  const result = _.chunk(truncatedArr, M);

  return result;
};

export const _formatSuperAndSubscript = (value) => {
  if (typeof value === typeof "str") {
    if (value === "") {
      return "-";
    }
    return value.split(/([㎡㎥])/).map((part, index) => {
      if (part === "㎡") {
        return (
          <React.Fragment key={index}>
            m<sup>2</sup>
          </React.Fragment>
        );
      } else if (part === "㎥") {
        return (
          <React.Fragment key={index}>
            m<sup>3</sup>
          </React.Fragment>
        );
      }
      return part;
    });
  }
  return value;
};

export const _formatUrl = (url) => {
  return url.replaceAll("+", encodeURIComponent("+"));
};

export const _setFiniteInterval = (callback, interval, times) => {
  let count = 0;
  callback();
  // 즉시 실행
  const intervalId = setInterval(() => {
    count++;
    callback();

    if (count >= times - 1) {
      clearInterval(intervalId); // 지정된 횟수만큼 실행 후 종료
    }
  }, interval);
};
