import React from "react";

import styles from "./Chip.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const Chip = ({
  children,
  clickable = false,
  clicked,
  onClick,
  GTM,
  fixed = false,
  type = "default",
}) => {
  return (
    <div
      className={cx(
        "wrapper",
        clickable ? "clickable" : "disable",
        clicked ? "clicked" : "unclicked",
        fixed ? "fixed" : "",
        type ? type : "default"
      )}
      onClick={clickable ? onClick : () => {}}
    >
      <div className={(GTM ? "GTM-" + GTM + " " : "") + cx("frame")}>
        {children}
      </div>
    </div>
  );
};

Chip.defaultProps = {
  children: "children",
  clicked: false,
  onClick: () => {
    console.log("clicked default chip");
  },
};

export default Chip;

// ### Chip

// children: any
// clickable: boolean
// clicked: boolean
// onClick: ()=>any
